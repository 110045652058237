import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addWololo } from '~/services/tasks';
import { closePostModal } from '~/store/ducks/post-modal';
import Close from '../close';
import './style.scss';

ReactModal.setAppElement('#root');

class PostModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrooled: false
    };

    this.overlayRef = null;
    this.handleClose = this.handleClose.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.enableScroll = this.enableScroll.bind(this);
    this.disableScroll = this.disableScroll.bind(this);
  }

  enableScroll() {
    if (this.overlayRef) {
      this.overlayRef.addEventListener('scroll', this.handleScroll);
    }
  }

  disableScroll() {
    if (this.overlayRef) {
      this.overlayRef.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleClose() {
    this.props.closePostModal();

    if (this.state.scrooled || this.props.postModal.ignoreScroll) {
      if (this.props.postModal.save) {
        if (this.props.postModal.isSubtask) {
          const { id, subtaskAction } = this.props.postModal;

          subtaskAction(id);
          return;
        }

        addWololo(this.props.postModal.id);
      }

      this.setState({ scrooled: false });
    }
  }

  handleScroll({ target }) {
    const max = target.scrollHeight - target.clientHeight;

    if (target.scrollTop >= max) {
      this.setState({ scrooled: true });
    }
  }

  render() {
    return (
      <ReactModal
        className="modal"
        overlayClassName="modal-overlay"
        isOpen={this.props.postModal.open}
        onRequestClose={this.handleClose}
        overlayRef={node => (this.overlayRef = node)}
        onAfterOpen={this.enableScroll}
        parentSelector={() => document.getElementById('modal-root')}
        onAfterClose={this.disableScroll}
      >
        <Close onClick={this.handleClose} />
        <div>{this.props.postModal.content}</div>
      </ReactModal>
    );
  }
}

const mapStateToProps = store => ({
  postModal: store.postModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ closePostModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostModal);
