import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';
import { useDispatch } from 'redux-react-hook';
import { createPortal } from 'react-dom';
import { NavLink, withRouter } from 'react-router-dom';

import { CRISP_WEBSITE_ID } from '~/settings';

import { getPages } from '~/api/organizations';

import { logoff } from '~/services/auth';

import { getConfig } from '~/utils';

import { unregisterAccount } from '~/store/ducks/account';

import useModalDOM from '~/hooks/use-modal-dom';
import useAccount from '~/hooks/use-account';
import useCurrentUserFirstName from '~/hooks/use-current-user-first-name';
import useSite from '~/hooks/use-site';

import BlockSiteScroll from '~/components/block-site-scroll';
import StoreLinks from '~/components/store-links';
import ToggleOrganization from '~/components/toggle-organization';
import Icon from '~/components/icon';

import './style.scss';

const MoreScreen = ({
  onClose,
  history,
  organizationIsActiveSet,
  organization: { activeOrganizationId }
}) => {
  const modalDom = useModalDOM();
  const dispatch = useDispatch();
  const account = useAccount();
  const currentUserFirstName = useCurrentUserFirstName();
  const { showStoreLinks, LogoImg } = useSite();

  const avatar = _.get(account, 'data.profile.avatar_128x0');
  const name = _.get(account, 'data.profile.name');

  const config = getConfig(organizationIsActiveSet, activeOrganizationId);

  const navOptions = [
    {
      path: '/training',
      title:
        config.moduleSettings && config.moduleSettings.training.name
          ? config.moduleSettings.training.name
          : 'Treinamentos',
      Icon: <Icon name="training" />
    },
    {
      path: '/pdi',
      title:
        config.moduleSettings && config.moduleSettings.pdi.name
          ? config.moduleSettings.pdi.name
          : 'Desenvolvimento',
      Icon: <Icon name="home" />
    },
    {
      path: '/connections',
      title: 'Conexões',
      Icon: <Icon name="connections" />
    },
    {
      path: '/rankings',
      title: 'Rankings',
      Icon: <Icon name="rankings" />
    },
    {
      path: '/forum',
      title: 'Fórum',
      Icon: <Icon name="home" />
    },
    {
      path: '/history',
      title: 'Histórico',
      Icon: <Icon name="history" />
    },
    {
      path: '/downloads',
      title: 'Downloads',
      Icon: <Icon name="download" />
    },
    {
      href: 'https://d1asjutgsmztwh.cloudfront.net/talkative/use-terms',
      title: 'Termos de uso e Política de privacidade',
      Icon: <Icon name="link" />
    },
    {
      href: 'https://d1asjutgsmztwh.cloudfront.net/talkative/cookie-policy',
      title: 'Política de Cookies',
      Icon: <Icon name="link" />
    },
    {
      path: '/forms',
      title: 'Pesquisas & Avaliações',
      Icon: <Icon name="forms" />
    },
    {
      path: '/library',
      title: 'Biblioteca',
      Icon: <Icon name="home" />
    },
    {
      path: '/metlife',
      title: 'Metlife',
      Icon: <Icon name="metlife" />
    },
    {
      title: 'Fale Conosco',
      Icon: <Icon name="feedback" />,
      href: 'https://suporte.talkative.media/',
      target: '_blank'
    },
    {
      path: '/help',
      title: 'Dúvidas Frequentes',
      Icon: <Icon name="help" />
    },
    {
      title: 'Suporte',
      Icon: <Icon name="feedback" />,
      href: `https://go.crisp.chat/chat/embed/?website_id=${CRISP_WEBSITE_ID}`,
      target: '_blank'
    },
    {
      Icon: <Icon name="logout" />,
      title: 'Sair',
      onClick: () => {
        dispatch(unregisterAccount());
        logoff();
        history.push('/sign-in');
      }
    }
  ];

  const [items, setItems] = useState([...navOptions]);

  const getOptions = organizationId => {
    const options = [...navOptions];

    return new Promise(resolve => {
      getPages(organizationId).then(response => {
        const initialOptions = options.slice(0, options.length - 4);
        const finalOptions = options.slice(options.length - 4, options.length);

        const pages = response.data.map(page => {
          const menuOption = {
            href: page.url,
            path: `/page/${page.id}`,
            title: page.title,
            Icon: <Icon name="link" />
          };

          if (page.url) {
            delete menuOption['path'];
          }

          return menuOption;
        });

        resolve(initialOptions.concat(pages).concat(finalOptions));
      });
    });
  };

  useEffect(() => {
    const organizationId = account.data ? activeOrganizationId : null;

    if (organizationId) {
      getOptions(organizationId).then(options => {
        setItems(
          options.filter(option => {
            if (option.path === '/downloads' && !config.moduleDownloads) {
              return false;
            }

            if (option.path === '/forum' && !config.moduleForum) {
              return false;
            }

            if (
              option.path === '/forms' &&
              !config.moduleSurvey &&
              !config.modulePerformance
            ) {
              return false;
            }

            if (option.path === '/rankings' && !config.moduleRanking) {
              return false;
            }

            if (option.path === '/library' && !config.moduleLibrary) {
              return false;
            }

            if (option.path === '/training' && !config.moduleTraining) {
              return false;
            }

            if (option.path === '/pdi' && !config.modulePDI) {
              return false;
            }

            if (option.path === '/contact-us' && !config.moduleContact) {
              return false;
            }

            if (option.path === '/metlife' && !config.moduleMetlife) {
              return false;
            }

            return true;
          })
        );
      });
    }
  }, [account.data, activeOrganizationId]);

  if (!modalDom) {
    return null;
  }

  const result = (
    <div className="more-screen">
      <BlockSiteScroll />
      <div className="more-screen-header">
        <img src={avatar} alt={name} className="header-avatar" />
        <div className="text-center">
          <NavLink className="button primary" to="/account" onClick={onClose}>
            Minha Conta
          </NavLink>
        </div>
      </div>

      <div className="more-screen-body">
        <h2 className="body-title">Olá, {currentUserFirstName}</h2>

        <ToggleOrganization organizations={organizationIsActiveSet} />

        {items.map(item => {
          if (item.href) {
            return (
              <a key={item.href} href={item.href} target={item.target}>
                {item.Icon}
                {item.title}
              </a>
            );
          } else if (item.path) {
            return (
              <NavLink key={item.path} to={item.path} onClick={onClose}>
                {item.Icon}
                {item.title}
              </NavLink>
            );
          } else if (item.onClick) {
            return (
              <button
                key={item.onClick}
                className="menu-button"
                onClick={item.onClick}
              >
                {item.Icon}
                {item.title}
              </button>
            );
          } else {
            throw new Error('You must pass href, path or onClick');
          }
        })}

        {showStoreLinks && (
          <StoreLinks>
            <p>
              Através do nosso app você também pode interagir com nossa
              plataforma.
            </p>
          </StoreLinks>
        )}

        <div className="more-screen-footer">
          <p>{config.organizationName} usa a tecnologia </p>
          <img src={LogoImg} alt="" />
          <p>
            {' '}
            {process.env.REACT_APP_VERSION
              ? ` v${process.env.REACT_APP_VERSION}`
              : ''}{' '}
            {process.env.REACT_APP_CURRENT_ENVIRONMENT !== 'prod'
              ? `(${process.env.REACT_APP_CURRENT_ENVIRONMENT})`
              : ''}{' '}
          </p>
        </div>
      </div>
    </div>
  );

  return createPortal(result, modalDom);
};

const mapStateToProps = store => ({
  organization: store.organization
});

export default connect(mapStateToProps)(withRouter(MoreScreen));
