import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import moment from 'moment';
import Card from '~/components/card';
import PostHeader from '~/components/post-header';
import PostComment from '~/components/post-comment';
import FakeLink from '~/components/fake-link';
import Tags from '~/components/tags';
import ViewPost from '~/components/view-post';
import Modal from '~/components/modal';
import PostImage from '~/components/post-image';
import { postLoaded } from '~/store/ducks/actions';
import { getPost } from '~/api/post';
import LikeButtons from '~/components/like-buttons';
import useComments from '~/hooks/use-comments';

export const ViewPostControl = ({ id, render }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getPost(id)
      .then(({ data }) => {
        dispatch(postLoaded(data));
        setData(data);
      })
      .catch(e => {
        setError(e);
      });
  }, []);

  return render({
    data,
    error
  });
};

const diffSegundosDate = (created_at, updated_at) => {
  const diffMilissegundos = new Date(updated_at) - new Date(created_at);
  return diffMilissegundos / 1000;
};

const Post = props => {
  const [showViewPost, setShowViewPost] = useState(false);

  const { count } = useComments('post', props.id);

  const onViewPostClose = useCallback(() => setShowViewPost(null));

  const viewPost = useCallback(() => {
    setShowViewPost(true);
  }, []);

  return (
    <>
      {showViewPost && (
        <Modal
          className="modal"
          overlayClassName="modal-overlay"
          onClose={onViewPostClose}
        >
          <ViewPostControl
            id={props.id}
            render={({ data }) => data && <ViewPost data={data} />}
          />
        </Modal>
      )}
      <Card>
        <PostHeader
          info="Publicação"
          icon_type="post"
          organization={props.original_data.organization}
          meTrail={props.me_trail}
        />
        <div className="card-body">
          <div className="content-card">
            <FakeLink
              to={`/post?id=${props.id}`}
              onClick={viewPost}
              data={{
                id: props.id
              }}
            >
              <PostImage url={props.image} />
            </FakeLink>
            <div className="title-date-task">
              <h3>{props.title}</h3>
              {props.updated_at && (
                <p>
                  {diffSegundosDate(props.created_at, props.updated_at) > 5
                    ? 'Atualizado em ' +
                      moment(props.updated_at).format('DD/MM/YYYY')
                    : 'Postado em ' +
                      moment(props.updated_at).format('DD/MM/YYYY')}
                </p>
              )}
            </div>
            <p className="text-center">{props.description}</p>
            {props.tags && <Tags>{props.tags}</Tags>}
          </div>
        </div>
        <footer className="card-footer">
          <div>
            {props.can_comment && (
              <PostComment className="inline-block" onClick={viewPost}>
                {count}
              </PostComment>
            )}
            {props.can_like && (
              <LikeButtons onClick={viewPost} type="post" id={props.id} />
            )}
          </div>
        </footer>
      </Card>
    </>
  );
};

export default Post;
